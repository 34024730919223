import { ERC20Token, WETH9, ChainId, WNATIVE, Native } from '@pancakeswap/sdk';
import { chainsSetup, chains } from '@sdk/constants';

// src/common.ts
var MULTEX = chainsSetup.reduce((acc, chain) => {
  if (!chain.platformAddress)
    return acc;
  return {
    ...acc,
    [chain.id]: new ERC20Token(chain.id, chain.platformAddress, 18, "MULTEX", "Multex", "https://multex.network/")
  };
}, {});
var PLATFORM_CURRENCY = MULTEX;
var USD = chains.reduce((acc, chain) => {
  if (!chain.stableToken)
    return acc;
  return {
    ...acc,
    [chain.id]: new ERC20Token(
      chain.id,
      chain.stableToken.address,
      chain.stableToken.decimals,
      chain.stableToken.symbol,
      chain.stableToken.name
    )
  };
}, {});
var STABLE_COIN = USD;

// ../utils/enumValues.ts
function* enumValues(enumObj) {
  let isStringEnum = true;
  for (const property in enumObj) {
    if (typeof enumObj[property] === "number") {
      isStringEnum = false;
      break;
    }
  }
  for (const property in enumObj) {
    if (isStringEnum || typeof enumObj[property] === "number") {
      yield enumObj[property];
    }
  }
}

// src/helpers.ts
var createEmptyList = () => {
  const list = {};
  for (const chainId of enumValues(ChainId)) {
    list[chainId] = {};
  }
  return list;
};
var EMPTY_LIST = createEmptyList();
function serializeTokens(unserializedTokens) {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: unserializedTokens[key].serialize };
  }, {});
  return serializedTokens;
}
function unwrappedToken(token) {
  if (token && token.equals(WNATIVE[token.chainId]))
    return Native.onChain(token.chainId);
  return token;
}
var ethereumTokens = {
  weth: WETH9[ChainId.ETHEREUM],
  usdt: USD[ChainId.ETHEREUM]
};
var bscTokens = {
  cake: MULTEX[ChainId.BSC],
  wbnb: WETH9[ChainId.BSC],
  usdt: new ERC20Token(ChainId.BSC, "0x55d398326f99059fF775485246999027B3197955", 18, "USDT", "Tether USD")
};
var bscTestnetTokens = {
  wbnb: WETH9[ChainId.BSC_TESTNET],
  // cake: CAKE_TESTNET,
  // busd: BUSD_TESTNET,
  syrup: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9",
    18,
    "SYRUP",
    "SyrupBar Token",
    "https://pancakeswap.finance/"
  ),
  hbtc: new ERC20Token(ChainId.BSC_TESTNET, "0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82", 18, "HBTC", "Huobi BTC"),
  wbtc: new ERC20Token(ChainId.BSC_TESTNET, "0xfC8bFbe9644e1BC836b8821660593e7de711e564", 8, "WBTC", "Wrapped BTC"),
  usdc: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0xCA8eB2dec4Fe3a5abbFDc017dE48E461A936623D",
    18,
    "USDC",
    "Binance-Peg USD Coin"
  ),
  usdt: new ERC20Token(ChainId.BSC_TESTNET, "0x0fB5D7c73FA349A90392f873a4FA1eCf6a3d0a96", 18, "USDT", "Tether USD"),
  mockBusd: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0x3304dd20f6Fe094Cb0134a6c8ae07EcE26c7b6A7",
    18,
    "BUSD",
    "Binance USD"
  ),
  mockB: new ERC20Token(ChainId.BSC_TESTNET, "0x828E3FC56dD48E072e3B6F3C4FD4DDB4733c2C5e", 18, "MOCK B", "MOCK B"),
  mockA: new ERC20Token(ChainId.BSC_TESTNET, "0xc1eD9955C11585F47d0d6BfBC29034349A746a81", 18, "MOCK A", "MOCK A"),
  msix: new ERC20Token(ChainId.BSC_TESTNET, "0xE4a9f36B61a84Dc2495dAf46417bd258a56bDfdD", 6, "MSIX", "MSIX"),
  cake2: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0x8d008B313C1d6C7fE2982F62d32Da7507cF43551",
    18,
    "CAKE2",
    "PancakeSwap Token",
    "https://pancakeswap.finance/"
  )
};
var zetachainTokens = {
  wzeta: WETH9[ChainId.ZETA],
  abs: MULTEX[ChainId.ZETA]
};
var zetachainAthensTokens = {
  wzeta: WETH9[ChainId.ZETA_TESTNET]
};
var zuluTestnetTokens = {
  wbtc: WETH9[ChainId.ZULU_TESTNET],
  multex: MULTEX[ChainId.ZULU_TESTNET]
};
var morphTestnetTokens = {
  weth: WETH9[ChainId.MORPH_TESTNET]
};
var shapeTestnetTokens = {
  weth: WETH9[ChainId.SHAPE_TESTNET],
  multex: MULTEX[ChainId.SHAPE_TESTNET]
};

export { EMPTY_LIST, MULTEX, PLATFORM_CURRENCY, STABLE_COIN, USD, bscTestnetTokens, bscTokens, ethereumTokens, morphTestnetTokens, serializeTokens, shapeTestnetTokens, unwrappedToken, zetachainAthensTokens, zetachainTokens, zuluTestnetTokens };

import { ChainId } from '@pancakeswap/sdk'
import { Address, GetContractReturnType, PublicClient, WalletClient, getContract } from 'viem'

import { iMulticallABI } from './abis/IMulticall'
import { MULTICALL3_ADDRESS, MULTICALL3_ADDRESSES, MULTICALL_ADDRESS } from './constants/contracts'

type Params = {
  chainId: ChainId
  client?: PublicClient
  wallet?: WalletClient
}

export function getMulticallContract({
  chainId,
  client,
  wallet,
}: Params): GetContractReturnType<typeof iMulticallABI, PublicClient> {
  const address = MULTICALL_ADDRESS[chainId]
  if (!address) {
    throw new Error(`PancakeMulticall not supported on chain ${chainId}`)
  }

  return getContract({
    abi: iMulticallABI,
    address,
    client: {
      public: client as PublicClient,
      wallet: wallet as WalletClient,
    },
  })
}

export function getMulticall3ContractAddress(chainId?: ChainId): Address {
  return MULTICALL3_ADDRESSES[chainId || ChainId.BSC] || MULTICALL3_ADDRESS
}
